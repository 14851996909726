import { HTMLAttributes } from 'react';
import styled, { CSSProperties } from 'styled-components';

export type FlexItemProps = {
  grow?: CSSProperties['flexGrow'];
  shrink?: CSSProperties['flexShrink'];
  width?: string;
  maxWidth?: string;
  height?: string;
} & HTMLAttributes<HTMLDivElement>;

export const FlexItem = styled.div<FlexItemProps>`
  ${(props) => (props.grow ? `flex-grow: ${props.grow};` : '')}
  ${(props) => (props.shrink !== undefined ? `flex-shrink: ${props.shrink}; ` : '')}
  ${(props) => (props.width ? `width: ${props.width};` : '')} 
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  ${(props) => (props.height ? `height: ${props.height};` : '')}
`;
