import { ApexOptions } from 'apexcharts';
import { ControlledSelect } from 'src/components/Form/ControlledSelect';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { format } from 'date-fns';
import { CHART_COLORS, TOKENS } from 'src/design';
import { keyBy, orderBy, take } from 'lodash';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { METRIC_OPTIONS, REPORTING_DEFAULT_CUSTOMIZE_COLUMNS, REPORTING_SORT_NUMBER_KEYS } from './constants';
import { ReportingFormValues } from './Reporting';
import { useDataTable, useRole, useWhiteLabelTheme } from 'src/hooks';
import { ControlledInput, ControlledSwitch } from 'src/components/Form';
import { DataTable, DataTableColumnCustomize, DataTableExport } from 'src/components/DataTable';
import { Loading } from 'src/components/Loading';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { getReportingMetricsColumns } from './utils';
import { useEntityTitleGenerator } from 'src/hooks/useEntityTitleGenerator';

const { useSiteTransparencyQuery } = growthApi;

const MAX_COUNT = 20;

type ReportingSitesFormValues = {
  metric?: string;
  search?: string;
  isWithMarkup?: boolean;
};

type ReportingSitesProps = ReportingFormValues;

export const ReportingSites = (props: ReportingSitesProps) => {
  const {
    timeRange,
    dateFrom,
    dateTo,
    previousDateFrom,
    previousDateTo,
    agencyId,
    advertiserId,
    campaignGroup,
    campaignId,
  } = props;

  const { isAdmin, canWithMarkup } = useRole();
  const theme = useWhiteLabelTheme();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;
  const isShowPrevious = Boolean(previousDateFrom && previousDateTo);

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
  };

  const title = useEntityTitleGenerator({
    agencyId,
    advertiserId,
    campaignId,
    campaignGroup,
    dateFrom,
    dateTo,
  });

  const { data, isFetching, error } = useSiteTransparencyQuery(commonFilter, { skip: isNotSelectAgencyAdvertiser });

  const {
    data: previousData,
    isFetching: previousIsFetching,
    error: previousError,
  } = useSiteTransparencyQuery(
    {
      ...commonFilter,
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
    },
    { skip: isNotSelectAgencyAdvertiser || !isShowPrevious },
  );

  const perviousBySite = useMemo(() => {
    return keyBy(previousData?.data, 'site_domain');
  }, [previousData]);

  const { watch, control } = useForm<ReportingSitesFormValues>({
    defaultValues: {
      metric: 'impressions',
      isWithMarkup: true,
    },
  });

  const values = watch();

  const chartData = useMemo(() => {
    let xaxisCategories: any[] = [];
    let seriesData: any[] = [];

    if (data?.data?.length) {
      const orderedData = orderBy(data.data, (row) => Number(row[values.metric!]), 'desc');
      const resultData = take(orderedData, MAX_COUNT);
      xaxisCategories = resultData.map((row) => row.site_domain);
      seriesData = resultData.map((row) => row[values.metric!]);
    }

    return { xaxisCategories, seriesData };
  }, [data, values.metric]);

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: chartData.xaxisCategories,
    },
    stroke: {
      curve: 'straight',
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: METRIC_OPTIONS.find((option) => option.value === values.metric)?.label,
      data: chartData.seriesData,
    },
  ];

  const { dataTableProps, dataTableExportProps, dataTableCustomizeColumnsProps } = useDataTable({
    name: 'reporting-sites',
    data: data?.data,
    isLoading: isFetching || (isShowPrevious && previousIsFetching),
    error: error || (isShowPrevious && previousError),
    search: values.search,
    searchKeys: ['site_domain'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: REPORTING_SORT_NUMBER_KEYS,
    defaultCustomizeColumns: REPORTING_DEFAULT_CUSTOMIZE_COLUMNS,
    enableTotal: true,
    columns: [
      {
        header: 'Site',
        accessor: 'site_domain',
        render: (value) => {
          return (
            <a href={`https://${value}`} target="_blank" rel="noreferrer">
              <Flex gap="md" align="center">
                <Text breakAll>{value}</Text>
              </Flex>
            </a>
          );
        },
        export: (value) => value,
        sortable: true,
        totalRender: () => {
          return 'Total';
        },
      },
      ...getReportingMetricsColumns({
        isWithMarkup: values.isWithMarkup,
        isAdmin,
        isShowPrevious,
        getPreviousData: (row) => perviousBySite[row.site_domain],
      }),
    ],
  });

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <ChartContainer>
        <Text size="md" weight={700}>
          {title}
        </Text>
        <Spacing size="lg" />
        <Flex gap="md" align="center">
          <Text size="xs">Metric</Text>
          <ControlledSelect name="metric" control={control} options={METRIC_OPTIONS} width="20rem" />
        </Flex>
        <Spacing size="lg" />
        {isFetching ? (
          <Loading height="40rem" />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={400} />
        )}
      </ChartContainer>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" justify="space-between" align="center" width="100%">
          <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" width="30rem" />
          <Flex gap="lg" align="center">
            {canWithMarkup && (
              <FlexItem shrink={0}>
                <Flex gap="md" align="center">
                  <Text size="xs">With markup</Text>
                  <ControlledSwitch name="isWithMarkup" control={control} />
                </Flex>
              </FlexItem>
            )}
            <DataTableColumnCustomize {...dataTableCustomizeColumnsProps} />
            <DataTableExport {...dataTableExportProps} />
          </Flex>
        </Flex>
        <Spacing size="lg" />
        <DataTable {...dataTableProps} />
      </TableContainer>
    </>
  );
};

const ChartContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
