import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { GlobalStyle } from './GlobalStyle';
import { Toasts } from 'src/components/Toasts';
import { store } from './store';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { StyleSheetManager } from 'styled-components';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENV !== 'local',
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <BrowserRouter>
        <Toasts />
        <StyleSheetManager disableCSSOMInjection>
          <App />
        </StyleSheetManager>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
