import styled, { css } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import { useAppDispatch, useAppSelector, userSlice } from 'src/store';
import { useMemo } from 'react';
import { Menu, MENUS, SubMenu } from './menus';
import { useWhiteLabelTheme } from 'src/hooks';
import { Flex, FlexItem } from 'src/components/Layout';

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const theme = useWhiteLabelTheme();
  const dispatch = useAppDispatch();

  const userMenus = useMemo(() => {
    const result: Menu[] = [];
    MENUS.forEach((menu) => {
      if (menu.when && !menu.when(user)) {
        return;
      }
      result.push({
        ...menu,
        subMenus: menu.subMenus?.filter((subMenu) => !(subMenu.when && !subMenu.when(user))),
      });
    });
    return result;
  }, [user]);

  const currentMenu = useMemo(() => {
    let parent: string | undefined = undefined;
    let sub: string | undefined = undefined;
    userMenus.forEach((menu) => {
      menu.subUrls?.forEach((subUrl) => {
        if (subUrl === location.pathname) {
          parent = menu.title;
          sub = '__EMPTY__';
        }
      });
      menu.subMenus?.forEach((subMenu) => {
        if (subMenu.url === location.pathname || subMenu.subUrls?.includes(location.pathname)) {
          parent = menu.title;
          sub = subMenu.title;
        }
      });
    });
    return {
      parent,
      sub,
    };
  }, [location.pathname, userMenus]);

  const onClickMenu = (menu: Menu | SubMenu) => {
    if (menu.url?.startsWith('http')) {
      window.location.href = menu.url;
    } else {
      const targetUrl = menu.url ?? (menu as Menu)?.subMenus?.[0]?.url;
      if (targetUrl) {
        navigate(targetUrl);
      }
    }
  };

  const onLogout = () => {
    dispatch(userSlice.actions.logout());
  };

  return (
    <SidebarContainer>
      <MenuContainer>
        <FlexItem grow={1}>
          <MenuItemContainer>
            {userMenus.map((menu) => (
              <MenuItem key={menu.title} onClick={() => onClickMenu(menu)}>
                <MenuIcon active={menu.title === currentMenu.parent}>
                  <Icon type={menu.icon} size="lg" />
                </MenuIcon>
                <div>{menu.title}</div>
              </MenuItem>
            ))}
          </MenuItemContainer>
        </FlexItem>
        <FlexItem shrink={0} height="8rem">
          <Flex clickable height="100%" direction="column" justify="center" align="center" gap="sm" onClick={onLogout}>
            <Icon type="logout" size="lg" />
            <div>Logout</div>
          </Flex>
        </FlexItem>
      </MenuContainer>
      {currentMenu.sub && (
        <SubMenuContainer>
          <LogoContainer>
            <img src={theme.logo} alt="logo" />
          </LogoContainer>
          {userMenus
            .find((menu) => menu.title === currentMenu.parent)
            ?.subMenus?.map((subMenu) => (
              <SubMenuItem
                key={subMenu.title}
                active={subMenu.title === currentMenu.sub}
                isLocked={subMenu.isLocked}
                onClick={() => onClickMenu(subMenu)}
              >
                <Icon type={subMenu.isLocked ? 'lock' : subMenu.icon} size="sm" />
                <div>{subMenu.title}</div>
              </SubMenuItem>
            ))}
          {userMenus.find((menu) => menu.title === currentMenu.parent).addition?.()}
        </SubMenuContainer>
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  border-radius: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: ${TOKENS.shadow.default};
  display: flex;
`;

const MenuContainer = styled.div`
  position: relative;
  width: 9rem;
  color: white;
  background: ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  font-size: 1.1rem;
  font-weight: 700;
`;

const MenuItemContainer = styled.div`
  position: absolute;
  top: 10rem;
  bottom: 8rem;
  left: 0;
  right: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
`;

const MenuIcon = styled.div<{ active: boolean }>`
  width: 5.3rem;
  height: 5.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  background: ${(props) => (props.active ? props.theme.color.highlight : props.theme.color.primaryDeep)};
`;

const SubMenuContainer = styled.div`
  position: relative;
  background: white;
  width: 23rem;
  box-sizing: border-box;
  padding: 0.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  font-size: 1.4rem;
  font-weight: 600;
  overflow-y: auto;
  padding-bottom: 3.6rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 9rem;
    height: 9rem;
  }
`;

const SubMenuItem = styled.div<{ active: boolean; isLocked: boolean }>`
  display: flex;
  gap: 1.2rem;

  ${(props) =>
    props.active
      ? css`
          color: ${props.theme.color.primary};
          font-weight: 600;
        `
      : css`
          color: ${props.theme.color.text};
          font-weight: 400;
        `};

  ${(props) =>
    props.isLocked
      ? css`
          color: ${props.theme.color.gray};
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;
