import DatePicker from 'react-datepicker';
import { useController, UseControllerProps } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { TOKENS } from 'src/design';
import { FlexItem, Text } from '../Layout';
import { endOfDay } from 'date-fns';

type ControlledDatePickerProps<T> = UseControllerProps<T> & {
  prefix?: string;
  placeholder?: string;
  showTimeSelect?: boolean;
  disabled?: boolean;
  width?: string;
  onValueChange?: (val: Date) => void;
};

export const ControlledDatePicker = <T,>(props: ControlledDatePickerProps<T>) => {
  const { prefix, placeholder, showTimeSelect, disabled, width, onValueChange, ...useControllerProps } = props;

  const {
    field: { value, onChange },
  } = useController(useControllerProps);

  const typedValue = (value as Date) || new Date();

  return (
    <Container disabled={disabled} width={width}>
      {prefix && (
        <FlexItem shrink={0}>
          <Text size="sm">{prefix}</Text>
        </FlexItem>
      )}
      <DatePicker
        selected={value as Date}
        onChange={(val) => {
          onChange(val);
          onValueChange?.(val);
        }}
        placeholderText={placeholder}
        dateFormat={showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'}
        showTimeSelect={showTimeSelect}
        injectTimes={[endOfDay(typedValue)]}
        disabled={disabled}
      />
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean; width?: string }>`
  width: ${(props) => props.width ?? '100%'};
  box-sizing: border-box;
  background: ${(props) => (props.disabled ? '#ddd' : 'white')};
  display: flex;
  align-items: center;
  padding: 1.2rem;
  border: solid 0.1rem ${TOKENS.color.grayLighter};
  border-radius: 1.2rem;
  box-shadow: ${TOKENS.shadow.default};

  input {
    flex-grow: 1;
    padding: 0 0.6rem;
    border: none;
    outline: none;
    width: 100%;
    background: ${(props) => (props.disabled ? '#ddd' : 'white')};

    ::placeholder {
      color: ${TOKENS.color.gray};
    }
  }
`;
