import { useMemo } from 'react';
import { growthApi } from 'src/services';
import { keyBy } from 'lodash';
import { fotmatDate } from 'src/utils';

const { useCampaignsQuery, useAgenciesQuery, useAdvertisersQuery } = growthApi;

const useEntityById = (queryHook) => {
  const { data } = queryHook();
  return useMemo(() => keyBy(data?.data, 'id'), [data]);
};

export const useEntityTitleGenerator = ({ agencyId, advertiserId, campaignId, campaignGroup, dateFrom, dateTo }) => {
  const agencyById = useEntityById(useAgenciesQuery);
  const advertiserById = useEntityById(useAdvertisersQuery);
  const campaignById = useEntityById(useCampaignsQuery);

  const campaignGroupTitle = useMemo(() => {
    if (campaignGroup && !campaignId.length) {
      return campaignGroup;
    }
    return campaignId?.map((item) => campaignById[item]?.campaign_name).join(', ');
  }, [campaignGroup, campaignId, campaignById]);

  const title = useMemo(() => {
    if (agencyId || advertiserId || campaignId) {
      return [
        agencyById[agencyId]?.name,
        advertiserById[advertiserId]?.name,
        campaignGroupTitle,
        fotmatDate(dateFrom),
        fotmatDate(dateTo),
      ]
        .filter(Boolean)
        .join(' - ');
    } else {
      return 'All';
    }
  }, [agencyId, advertiserId, campaignId, agencyById, advertiserById, campaignGroupTitle, dateFrom, dateTo]);

  return title;
};
