import { assign, pick } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useRole } from './useRole';
import { useDeepEffect } from './useDeepEffect';
import { useEffectOnce } from './useEffectOnce';

export const PERSISTENT_FILTERS_STORAGE_KEY = 'filters';

const DEFAULT_FN = (filters: any) => filters;

export type UsePersistentFiltersOptions = {
  filters: string[];
  persistentFn?: (filters: any) => any;
  recoverFn?: (filters: any) => any;
  values?: any;
  setValues?: (values: any) => void;
};
const transformFiltersToUrlParams = (filters: Record<string, any>): URLSearchParams => {
  const urlParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      urlParams.set(key, String(value).replace(/\//g, '-').replace(/,/g, ' '));
    }
  });

  return urlParams;
};

const transformUrlParamsToFilters = (searchParams: URLSearchParams): Record<string, any> => {
  const filters: Record<string, any> = {};

  searchParams.forEach((value, key) => {
    const decodedValue = decodeURIComponent(value);

    switch (key) {
      case 'agencyId':
      case 'advertiserId':
        filters[key] = decodedValue === 'null' ? null : Number(decodedValue);
        break;

      case 'dateFrom':
      case 'dateTo':
        filters[key] = decodedValue.replace(/-/g, '/');
        break;

      case 'campaignId':
        filters[key] = decodedValue.replace(/ /g, ',');
        break;

      default:
        filters[key] = decodedValue;
        break;
    }
  });

  return filters;
};
export const usePersistentFilters = (options: UsePersistentFiltersOptions) => {
  const { filters, persistentFn = DEFAULT_FN, recoverFn = DEFAULT_FN, values, setValues } = options;

  const { agencyId, advertiserId } = useRole();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffectOnce(() => {
    const persistentFiltersFromUrl = transformUrlParamsToFilters(searchParams);
    const persistentFilters = pick(
      assign(
        { agencyId, advertiserId },
        JSON.parse(localStorage.getItem(PERSISTENT_FILTERS_STORAGE_KEY) || '{}'),
        persistentFiltersFromUrl,
      ),
      filters,
    );

    setValues?.(assign(values, recoverFn(persistentFilters)));

    setSearchParams(() => {
      const newSearchParams = transformFiltersToUrlParams(persistentFilters);
      return newSearchParams;
    });
  });

  useDeepEffect(() => {
    if (!values) return;

    const persistentFilters = pick(persistentFn(values), filters);
    const oldStorage = JSON.parse(localStorage.getItem(PERSISTENT_FILTERS_STORAGE_KEY) || '{}');

    localStorage.setItem(PERSISTENT_FILTERS_STORAGE_KEY, JSON.stringify(assign(oldStorage, persistentFilters)));

    setSearchParams(() => {
      const newSearchParams = transformFiltersToUrlParams(persistentFilters);
      return newSearchParams;
    });
  }, [values]);
};
