import { Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { pick } from 'lodash';
import { useCommonPersistentFilters, useDataTable, useRole } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import { DataTable, DataTableExport } from 'src/components/DataTable';
import { ArchiveNew } from 'src/components/Archive';
import { formatCount, formatDate } from 'src/utils';
import { GetPixelCodeModal } from './GetPixelCodeModal';
import { Role } from 'src/types';
import styled from 'styled-components';
import { TOKENS } from 'src/design';

const { usePixelsQuery, useArchivePixelMutation } = growthApi;

type PixelsFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Pixels = () => {
  const { canAccessAgency, isViewOnly } = useRole();
  const { watch, control, setValue, reset } = useForm<PixelsFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });
  const [isGetPixelCodeOpen, setIsGetPixelCodeOpen] = useState<boolean>(false);

  const { data, isFetching, error, refetch } = usePixelsQuery({
    ...pick(values, ['agency_id', 'advertiser_id']),
    with_stats: true,
  });
  const [archivePixel, { isLoading: isArchiving }] = useArchivePixelMutation();
  const onArchive = async (row: any) => {
    await archivePixel({
      uuid: row.uuid,
    }).unwrap();
    refetch();
  };

  const { dataTableProps, dataTableExportProps, selectedRow, setSelectedRow } = useDataTable({
    name: 'pixels',
    idKey: 'uuid',
    isLoading: isFetching,
    data: data?.data,
    error,
    search: values.search,
    searchKeys: ['uuid', 'pixel_name', 'created_at'],
    sortNumberKeys: ['total_fire', 'yesterday_fire'],
    defaultSort: {
      key: 'created_at',
      direction: 'desc',
    },
    columns: [
      {
        header: 'UUID',
        accessor: 'uuid',
        sortable: true,
      },
      { header: 'Pixel Name', accessor: 'pixel_name', sortable: true },
      { header: 'Advertiser', accessor: 'advertiser_name', sortable: true },
      { header: "Total's Fire", accessor: 'total_fire', sortable: true, render: formatCount },
      { header: '24 Hour Fires', accessor: 'yesterday_fire', sortable: true, render: formatCount },
      {
        header: 'Time Since Last Fire',
        accessor: 'last_fire',
        sortable: true,
        render: (value) => {
          if (!value) return '-';
          const days = Math.floor(value / (24 * 60 * 60));
          const hours = Math.floor((value % (24 * 60 * 60)) / (60 * 60));
          const minutes = Math.floor((value % (60 * 60)) / 60);
          const seconds = value % 60;

          if (days > 0) {
            return `${days}d ${hours}h`;
          } else if (hours > 0) {
            return `${hours}h ${minutes}m`;
          } else {
            return `${minutes}m ${seconds}s`;
          }
        },
      },
      { header: 'Created At', accessor: 'created_at', render: formatDate, sortable: true },
      {
        header: 'Get Pixel',
        accessor: 'download',
        render: (_, row) => (
          <Flex gap="md">
            <Flex
              gap="xxs"
              clickable
              onClick={() => {
                setSelectedRow(row);
                setIsGetPixelCodeOpen(true);
              }}
            >
              <Icon color="primary" size="sm" type="code" />
              <Text color="primary" weight={600}>
                Get pixel code
              </Text>
            </Flex>
          </Flex>
        ),
        export: false,
      },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        when: (user) => user?.role !== Role.ViewOnly,
        render: (_, row) => {
          return (
            <Flex gap="md">
              <Link to={`/activate/pixels/edit?uuid=${row.uuid}`}>
                <Text size="xs" weight={600} color="primary" clickable>
                  Edit
                </Text>
              </Link>
              <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
            </Flex>
          );
        },
        export: false,
      },
    ],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Pixels
          </Text>
        </Flex>
        {!isViewOnly && (
          <Flex gap="lg">
            <Link to="/activate/pixels/new">
              <Button width="16rem" shadow>
                NEW PIXEL
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
      {canAccessAgency && (
        <>
          <Spacing size="lg" />
          <Flex gap="md">
            <AgencySelect
              prefix="Agency:"
              name="agency_id"
              control={control}
              withAll
              width="22rem"
              onValueChange={() => {
                setValue('advertiser_id', null);
              }}
            />
            <AdvertiserSelect
              agencyId={values.agency_id}
              prefix="Advertiser:"
              name="advertiser_id"
              control={control}
              withAll
              width="22rem"
            />
          </Flex>
        </>
      )}
      <Spacing size="xl" />
      <TableContainer>
        <Flex justify="space-between" align="center">
          <ControlledInput width="40rem" name="search" control={control} prefix="Search:" placeholder="Keyword" />
          <DataTableExport {...dataTableExportProps} />
        </Flex>
        <Spacing />
        <DataTable {...dataTableProps} scroll />
      </TableContainer>
      <GetPixelCodeModal isOpen={isGetPixelCodeOpen} onClose={() => setIsGetPixelCodeOpen(false)} pixel={selectedRow} />
    </PageTemplate>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
