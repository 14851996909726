import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiResult, User } from 'src/types';
import { RootState, userSlice } from 'src/store';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { omitBy, isNil } from 'lodash';

// 30 mins
// const DEFAULT_CACHE_TIME = 1800;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_GROWTH_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWrapper: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  // auto logout when api returns 401
  if (result.error?.status === 401) {
    api.dispatch(userSlice.actions.logout());
  }
  return result;
};

export const growthApi = createApi({
  reducerPath: 'growthApi',
  baseQuery: baseQueryWrapper,
  endpoints: (builder) => ({
    login: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/login',
        method: 'POST',
        body,
      }),
    }),
    v1Login: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'v1/login',
        method: 'POST',
        body,
      }),
    }),
    v1SyncUsers: builder.mutation<ApiResult, void>({
      query: (body) => ({
        url: 'v1/sync-users',
        method: 'POST',
        body,
      }),
    }),
    signup: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/signup',
        method: 'POST',
        body,
      }),
    }),
    confirm: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/confirm',
        method: 'POST',
        body,
      }),
    }),
    forgetPassword: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/forget-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/reset-password',
        method: 'POST',
        body,
      }),
    }),
    simpleChangePassword: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/simple-change-password',
        method: 'POST',
        body,
      }),
    }),
    changeEmail: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/change-email',
        method: 'POST',
        body,
      }),
    }),
    changeEmailRecieve: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/change-email-recieve',
        method: 'POST',
        body,
      }),
    }),
    profile: builder.query<ApiResult, void>({
      query: () => ({
        url: 'user/profile',
        method: 'GET',
      }),
    }),
    softDeleteUser: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user',
        method: 'DELETE',
        body,
      }),
    }),
    updateProfile: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/update-profile',
        method: 'POST',
        body,
      }),
    }),
    updateUserProfile: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/update-user-profile',
        method: 'POST',
        body,
      }),
    }),
    creditBalance: builder.query<ApiResult, void>({
      query: () => ({
        url: 'user/credit-balance',
        method: 'GET',
      }),
    }),
    useCreditBalance: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `user/user-credit-balance?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    userCharge: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/user-charge',
        method: 'POST',
        body,
      }),
    }),
    createUser: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/create-user',
        method: 'POST',
        body,
      }),
    }),
    sendTeamInvite: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/team/send-invite',
        method: 'POST',
        body,
      }),
    }),
    getTeamInvite: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `user/team/get-invite?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    acceptTeamInvite: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/team/accept-invite',
        method: 'POST',
        body,
      }),
    }),
    addCreditBalance: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/credit-balance',
        method: 'POST',
        body,
      }),
    }),
    setBillingInformation: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/billing-information',
        method: 'POST',
        body,
      }),
    }),
    setAutoCredit: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/auto-credit',
        method: 'POST',
        body,
      }),
    }),
    audiences: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    audiencesDataset: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences-dataset?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    thirdPartyAudienceProviders: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences/3rd-party-audience-providers?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    thirdPartyAudiences: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences/3rd-party-audiences?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    archiveAudience: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/audiences/archive',
        method: 'POST',
        body,
      }),
    }),
    applyAudienceExclusionRules: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/audiences/apply-audience-exclusion-rules',
          method: 'POST',
          body,
        };
      },
    }),
    audiencesDatasetQuery: builder.mutation<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences-dataset?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    creatives: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/creatives?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    url: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/url?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    currency: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/currency?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    creativeReports: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/creative-reports?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    creativePreview: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/creatives/preview?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    geo: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/geo?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    pixels: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/pixels?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    campaigns: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/campaigns?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createOrUpdateCampaign: builder.mutation<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaigns',
          method: 'POST',
          body,
        };
      },
    }),
    archiveCampaign: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/campaigns/archive',
        method: 'POST',
        body,
      }),
    }),
    setCampaignActive: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/campaigns/active',
        method: 'POST',
        body,
      }),
    }),
    campaignFileCreate: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaign-file-create',
          method: 'POST',
          body,
        };
      },
    }),
    campaignFileUpdate: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaign-file-update',
          method: 'POST',
          body,
        };
      },
    }),
    checkDoubleUpload: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/check-double-upload',
          method: 'POST',
          body,
        };
      },
    }),
    // bulkUpload: builder.mutation<ApiResult<any[]>, any>({
    //   query: (body) => {
    //     let formData = body;
    //     let query = null;
    //     if (Object.keys(body).length) {
    //       formData = body.formData;
    //       query = body.query;
    //     }
    //     return {
    //       url: `dsp/admin-campaign-upload?${new URLSearchParams(omitBy(query, isNil)).toString()}`,
    //       method: 'POST',
    //       body: formData,
    //     };
    //   },
    // }),
    bulkUploadCampaigns: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/bulk-upload/campaigns',
          method: 'POST',
          body,
        };
      },
    }),
    bulkUploadPixels: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/bulk-upload/pixels',
          method: 'POST',
          body,
        };
      },
    }),
    bulkUploadBeeswaxCampaigns: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/bulk-upload/beeswax-campaigns',
          method: 'POST',
          body,
        };
      },
    }),
    bulkUploadBeeswaxCreatives: builder.mutation<ApiResult<any>, any>({
      query: (body) => {
        return {
          url: 'dsp/bulk-upload/beeswax-creatives',
          method: 'POST',
          body,
        };
      },
    }),
    // updateCreatives: builder.mutation<ApiResult<any[]>, any>({
    //   query: (body) => {
    //     return {
    //       url: 'dsp/admin-creative-update',
    //       method: 'POST',
    //       body,
    //     };
    //   },
    // }),
    // segmentsUpdate: builder.mutation<ApiResult<any[]>, any>({
    //   query: (body) => {
    //     return {
    //       url: 'dsp/admin-segments-update',
    //       method: 'POST',
    //       body,
    //     };
    //   },
    // }),
    campaignGroups: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/campaigns/groups?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    setCampaignGroup: builder.mutation<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaigns/set-group',
          method: 'POST',
          body,
        };
      },
    }),
    createOrUpdateDspCampaignMapping: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/campaigns/dsp-mapping',
          method: 'POST',
          body,
        };
      },
    }),
    forecasts: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/forecasts?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createOrUpdateForecast: builder.mutation<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/forecasts',
          method: 'POST',
          body,
        };
      },
    }),
    customAudiences: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    shareCustomAudience: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/custom-audiences/share',
          method: 'POST',
          body,
        };
      },
    }),
    customAudienceActivations: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences/activations?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createCustomAudienceActivation: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/custom-audiences/activations',
          method: 'POST',
          body,
        };
      },
    }),
    customAudienceInsights: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences/insights?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    customAudienceInsightArchive: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/custom-audiences/insights/archive',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateCustomAudienceInsight: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/custom-audiences/insights',
          method: 'POST',
          body,
        };
      },
    }),
    activateCustomAudienceInsight: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences/activate?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    markupRules: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/markup/markup-rules?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    updateMarkupRules: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/markup/markup-rules',
          method: 'POST',
          body,
        };
      },
    }),
    markupMappings: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/markup-mapping',
          method: 'POST',
          body,
        };
      },
    }),
    campaignPerformance: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/campaign-performance?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    campaignPerformanceBatch: builder.query<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaign-performance-batch',
          method: 'POST',
          body,
        };
      },
    }),
    siteTransparency: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/site-transparency?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    appTransparency: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/app-transparency?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    winLoss: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/win-loss?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    conversion: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/conversion?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    adExchange: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/ad-exchange?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
      // keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    agencies: builder.query<ApiResult<any[]>, any>({
      query: (params) => ({
        url: `dsp/agencies?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    advertisers: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/advertisers?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    archiveAgency: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/agencies/archive',
        method: 'POST',
        body,
      }),
    }),
    archiveAdvertiser: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/advertisers/archive',
        method: 'POST',
        body,
      }),
    }),
    updateDataCredits: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/update-data-credits',
        method: 'POST',
        body,
      }),
    }),
    verticals: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/verticals?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createOrUpdatePixel: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/pixels',
        method: 'POST',
        body,
      }),
    }),
    archivePixel: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/pixels/archive',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateCreative: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/creatives',
        method: 'POST',
        body,
      }),
    }),
    archiveCreative: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/creatives/archive',
        method: 'POST',
        body,
      }),
    }),
    setCreativeActive: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/creatives/active',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateAudience: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/audiences',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateAgency: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/agencies',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateAdvertiser: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/advertisers',
        method: 'POST',
        body,
      }),
    }),
    users: builder.query<ApiResult<User[]>, any>({
      query: (params) => ({
        url: `user/list?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    userDSPBinding: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/dsp-binding',
        method: 'POST',
        body,
      }),
    }),
    dspArchive: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/archive',
        method: 'POST',
        body,
      }),
    }),
    platforms: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/platforms?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    createOrUpdatePlatform: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/platforms',
        method: 'POST',
        body,
      }),
    }),
    archivePlatform: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/platforms/archive',
        method: 'POST',
        body,
      }),
    }),
    transactions: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/ad-wallet/transactions?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    archiveTransaction: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/transactions/archive',
        method: 'POST',
        body,
      }),
    }),
    manualDspBilling: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/daily-billing-manually',
        method: 'POST',
        body,
      }),
    }),
    status: builder.query<ApiResult, any>({
      query: (body) => ({
        url: 'user/status',
        method: 'POST',
        body,
      }),
    }),
    updateAgencyExt: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/agency-ext',
        method: 'POST',
        body,
      }),
    }),
    updateAdvertiserExt: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/advertiser-ext',
        method: 'POST',
        body,
      }),
    }),
    whiteLabel: builder.query<ApiResult, any>({
      query: () => ({
        url: 'dsp/white-label',
        method: 'GET',
      }),
    }),
    updateWhiteLabel: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/white-label',
        method: 'POST',
        body,
      }),
    }),
    connectWhiteLabel: builder.query<ApiResult, any>({
      query: () => ({
        url: 'dsp/white-label/connect',
        method: 'GET',
      }),
    }),
    refreshConnectWhiteLabel: builder.query<ApiResult, any>({
      query: () => ({
        url: 'dsp/white-label/connect-refresh',
        method: 'GET',
      }),
    }),
    finishConnectWhiteLabel: builder.query<ApiResult, any>({
      query: () => ({
        url: 'dsp/white-label/connect-finish',
        method: 'GET',
      }),
    }),
    adWallet: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/ad-wallet?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    updateAdWalletPaymentMethod: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/update-payment-method',
        method: 'POST',
        body,
      }),
    }),
    addAdWalletCreditBalance: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/add-credit-balance',
        method: 'POST',
        body,
      }),
    }),
    adWalletPrepayment: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/prepayment',
        method: 'POST',
        body,
      }),
    }),
    stackadaptCustomSegments: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/stackadapt/custom-segments?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    createOrUpdateMarketPlan: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/market-plans',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdatePersona: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/personas',
        method: 'POST',
        body,
      }),
    }),
    personas: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `planning/personas?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    marketPlans: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `planning/market-plans?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    updateWorkspace: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/workspace',
        method: 'POST',
        body,
      }),
    }),
    cancelSubscription: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/cancel-subscription',
        method: 'POST',
        body,
      }),
    }),
    securityConfig: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `security/config?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
    updateSecurityConfig: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'security/config',
        method: 'POST',
        body,
      }),
    }),
    clearIpExclusion: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'security/clear-ip-exclusion',
        method: 'POST',
        body,
      }),
    }),
    getNotifications: builder.query<ApiResult, any>({
      query: () => ({
        url: `notifications`,
        method: 'GET',
      }),
    }),
    readNotification: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `notifications/${params.id}/read`,
        method: 'GET',
      }),
    }),
    readAllNotification: builder.query<ApiResult, any>({
      query: () => ({
        url: `notifications/read-all`,
        method: 'GET',
      }),
    }),
    countNotifications: builder.query<ApiResult, any>({
      query: () => ({
        url: `notifications/count`,
        method: 'GET',
      }),
    }),
    detectPixel: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `utils/detect-pixel?${new URLSearchParams(omitBy(params, isNil)).toString()}`,
        method: 'GET',
      }),
    }),
  }),
  // disable cache
  keepUnusedDataFor: 0,
});
