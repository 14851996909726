import { useNavigate } from 'react-router-dom';
import { PageTemplate } from 'src/components/Template';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Icon } from 'src/components/Icon';
import { Button, Form, FormItem } from 'src/components/Form';
import styled, { css } from 'styled-components';
import { userSlice } from 'src/store';
import { growthApi } from 'src/services';
import { useRole, useToast } from 'src/hooks';
import { TOKENS } from 'src/design';
import { getUserName } from 'src/helpers';
import { useState } from 'react';
import { Modal } from 'src/components/Modal';
import { useAppDispatch } from 'src/store';
import { getApiErrorMessage } from 'src/utils';

const { useSoftDeleteUserMutation, useCancelSubscriptionMutation } = growthApi;

export const Profile = () => {
  const { user, whiteLabelId, isViewOnly } = useRole();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [softDeleteUser] = useSoftDeleteUserMutation();
  const [cancelSub] = useCancelSubscriptionMutation();

  const { showSuccessToast, showErrorToast } = useToast();

  const onConfirmDelete = async () => {
    await softDeleteUser({}).unwrap();
    dispatch(userSlice.actions.logout());
  };

  const onConfirmCancelSub = async () => {
    try {
      await cancelSub({}).unwrap();
      showSuccessToast('Subscription canceled successfully');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate>
      <Modal
        title="Confirmation"
        confirmVarious="dangerous"
        confirmText="Delete"
        isOpen={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={onConfirmDelete}
      >
        <Flex justify="center">
          <Text size="md">Are you sure you want to delete your account?</Text>
        </Flex>
      </Modal>
      <Modal
        title="Confirmation"
        confirmVarious="dangerous"
        isOpen={isCancelConfirmOpen}
        onClose={() => setIsCancelConfirmOpen(false)}
        onConfirm={onConfirmCancelSub}
      >
        <Flex justify="center">
          <Text size="md">Are you sure you want to cancel your subscription?</Text>
        </Flex>
      </Modal>
      <Flex direction="column" justify="center" align="left" minHeight="25vh">
        <Text size="xxl" weight={700}>
          My Profile
        </Text>
        <Spacing size="xxl" />
        <Form width="50%">
          <FormItem label="Name">
            <ProfileItem>
              <Flex justify="space-between" align="center">
                <Text size="sm" weight={500}>
                  {getUserName(user)}
                </Text>
                <Flex align="center" gap="xs" onClick={() => navigate('/change-user-name')} clickable>
                  <Text size="xs" color={TOKENS.color.primaryDeep}>
                    Change
                  </Text>
                  <Icon type="arrowRight" color="primary" />
                </Flex>
              </Flex>
            </ProfileItem>
          </FormItem>
          <FormItem label="Email">
            <ProfileItem>
              <Text>{user?.email}</Text>
            </ProfileItem>
          </FormItem>
          <FormItem label="Company Name">
            <ProfileItem>
              <Flex justify="space-between" align="center">
                <Text size="sm" weight={500}>
                  {user?.company_name || '-'}
                </Text>
                <Flex align="center" gap="xs" onClick={() => navigate('/change-company-name')} clickable>
                  <Text size="xs" color={TOKENS.color.primaryDeep}>
                    Change
                  </Text>
                  <Icon type="arrowRight" color="primary" />
                </Flex>
              </Flex>
            </ProfileItem>
          </FormItem>
          <FormItem label="Password">
            <ProfileItem>
              <Flex justify="space-between" align="center">
                <Text size="sm" weight={500}>
                  *********
                </Text>
                <Flex align="center" gap="xs" onClick={() => navigate('/simple-change-password')} clickable>
                  <Text size="xs" color={TOKENS.color.primaryDeep}>
                    Change password
                  </Text>
                  <Icon type="arrowRight" color="primary" />
                </Flex>
              </Flex>
            </ProfileItem>
          </FormItem>
          {!whiteLabelId && (
            <>
              <FormItem label="Subscription Plan">
                <ProfileItem>
                  <Text>{user?.workspace?.plan ? user.workspace.plan : '-'}</Text>
                </ProfileItem>
              </FormItem>
              {!isViewOnly && (
                <>
                  <FormItem>
                    <Button onClick={() => navigate('/planning/pricing')}>Upgrade Subscription</Button>
                  </FormItem>
                  <FormItem>
                    <Button various="dangerous" onClick={() => setIsCancelConfirmOpen(true)}>
                      Cancel Subscription
                    </Button>
                  </FormItem>
                </>
              )}
            </>
          )}
          {!isViewOnly && (
            <FormItem>
              <Button various="dangerous" onClick={() => setIsDeleteConfirmOpen(true)}>
                Delete Account
              </Button>
            </FormItem>
          )}
        </Form>
      </Flex>
    </PageTemplate>
  );
};

const ProfileItem = styled.div<{ disabled?: boolean }>`
  border-radius: 1.2rem;
  padding: 0.6rem 1.2rem;
  min-height: 2.3rem;

  ${(props) =>
    props.disabled
      ? css`
          background: ${props.theme.color.grayLighter};
          cursor: not-allowed;
        `
      : css`
          border: solid 1px #e5e5e5;
        `}
`;
