import { keyBy } from 'lodash';
import { ControlledRadioGroupOption, ControlledSelectOption } from 'src/components/Form';
import { CampaignChannel, CampaignConversionLocationType, CampaignDeviceType, CreativeType } from 'src/types';

export const CAMPAIGN_CHANNEL_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Digital Out of Home',
    value: CampaignChannel.DigitalOutOfHome,
  },
  {
    label: 'CTV / OTT',
    value: CampaignChannel.CTVOrOTT,
  },
  {
    label: 'Audio',
    value: CampaignChannel.Audio,
  },
  {
    label: 'Video',
    value: CampaignChannel.Video,
  },
  {
    label: 'Native',
    value: CampaignChannel.Native,
  },
  {
    label: 'Display',
    value: CampaignChannel.Display,
  },
  {
    label: 'Meta',
    value: CampaignChannel.Meta,
  },
];

export const CAMPAIGN_CHANNEL_OPTIONS_BY_VALUE = keyBy(CAMPAIGN_CHANNEL_OPTIONS, 'value');

export const CAMPAIGN_CHANNEL_CREATIVE_TYPES_MAPPING: Record<CampaignChannel, CreativeType[]> = {
  [CampaignChannel.DigitalOutOfHome]: [CreativeType.BannerAd, CreativeType.VideoAd],
  [CampaignChannel.CTVOrOTT]: [CreativeType.VideoAd],
  [CampaignChannel.Audio]: [CreativeType.AudioAd],
  [CampaignChannel.Video]: [CreativeType.VideoAd],
  [CampaignChannel.Native]: [CreativeType.NativeAd],
  [CampaignChannel.Display]: [CreativeType.BannerAd, CreativeType.HTML5Ad, CreativeType.AdTag],
  [CampaignChannel.Meta]: [CreativeType.MetaSingleImageVideoAd],
};

export const CAMPAIGN_CONVERSION_LOCATION_TYPE_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Website',
    value: CampaignConversionLocationType.Website,
  },
  // {
  //   label: 'Instant Form',
  //   value: CampaignConversionLocationType.InstantForm,
  // },
  {
    label: 'Calls',
    value: CampaignConversionLocationType.Calls,
  },
];

export const CAMPAIGN_DEVICE_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Mobile',
    value: CampaignDeviceType.Mobile,
  },
  {
    label: 'Desktop',
    value: CampaignDeviceType.Desktop,
  },
];

export const CAMPAIGN_META_CUSTOMIZE_PLACEMENTS_OPTIONS = [
  {
    label: 'All',
    value: null,
  },
  ...[
    'Facebook Feed',
    'Facebook profile feed',
    'Facebook Marketplace',
    'Facebook video feeds',
    'Facebook right column',
    'Facebook Business Explore',
    'Messenger inbox',
    'Instagram feed',
    'Instagram profile feed',
    'Instagram Explore',
    'Instagram Explore home',
    'Facebook Stories',
    'Messenger Stories',
    'Facebook Reels',
    'Instagram Stories',
    'Instagram Reels',
    'Instagram profile reels',
    'Facebook in-stream videos',
    'Ads on Facebook Reels',
    'Facebook search results',
    'Instagram search results',
    'Messenger sponsored messages',
    'Audience Network native, banner and interstitial',
    'Audience Network rewarded videos',
  ].map((item) => ({
    label: item,
    value: item,
  })),
];

export const CAMPAIGN_META_INSTANT_FORM_FIELD_OPTIONS = [
  'Email',
  'Phone number',
  'Street address',
  'City',
  'State',
  'Province',
  'Country',
  'Post code',
  'Zip code',
  'Full name',
  'First name',
  'Last name',
  'Date of birth',
  'Gender',
  'Marital status',
  'Relationship status',
  'Military status',
  'Job title',
  'Work phone number',
  'Work email',
  'Company name',
  'CPF (Brazil)',
  'DNI (Argentina)',
  'DNI (Peru)',
  'PUT (Chile)',
  'CC (Colombia)',
  'CI (Ecuador)',
  'RFC (Mexico)',
].map((item) => ({
  label: item,
  value: item,
}));
