import { ApexOptions } from 'apexcharts';
import { ControlledInput, ControlledSelect } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { format } from 'date-fns';
import { CHART_COLORS, TOKENS } from 'src/design';
import ReactApexChart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { AUCTION_METRIC_OPTIONS } from './constants';
import { ReportingFormValues } from './Reporting';
import { useDataTable, useWhiteLabelTheme } from 'src/hooks';
import { DataTable, DataTableExport } from 'src/components/DataTable';
import { formatPercentage } from 'src/utils';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { useEntityTitleGenerator } from 'src/hooks/useEntityTitleGenerator';

const { useWinLossQuery } = growthApi;

type ReportingAuctionResultsFormValues = {
  metric1?: string;
  metric2?: string;
  search?: string;
};

type ReportingAuctionResultsProps = ReportingFormValues;

export const ReportingAuctionResults = (props: ReportingAuctionResultsProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignGroup, campaignId } = props;

  const theme = useWhiteLabelTheme();
  const { watch, control } = useForm<ReportingAuctionResultsFormValues>({
    defaultValues: {
      metric1: 'matched_bid_opportunities',
      metric2: 'bids',
    },
  });
  const values = watch();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
  };

  const title = useEntityTitleGenerator({
    agencyId,
    advertiserId,
    campaignId,
    campaignGroup,
    dateFrom,
    dateTo,
  });

  const { data } = useWinLossQuery(
    {
      ...commonFilter,
      breakout: 'day',
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const {
    data: campaignData,
    isFetching: campaignIsLoading,
    error: campaignError,
  } = useWinLossQuery(
    {
      ...commonFilter,
      breakout: 'campaign',
      with_campaign: true,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      categories: data?.data?.map((row) => row.start_date) || [],
    },
    yaxis: [
      { min: 0, forceNiceScale: true, decimalsInFloat: 2 },
      {
        min: 0,
        forceNiceScale: true,
        decimalsInFloat: 2,
        opposite: true,
      },
    ],
    stroke: {
      curve: 'straight',
      width: 4,
    },
    fill: {
      type: 'solid',
      opacity: [0.35, 0.35],
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: AUCTION_METRIC_OPTIONS.find((option) => option.value === values.metric1)?.label,
      data: data?.data?.map((row) => row[values.metric1]) || [],
    },
    {
      name: AUCTION_METRIC_OPTIONS.find((option) => option.value === values.metric2)?.label,
      data: data?.data?.map((row) => row[values.metric2]) || [],
    },
  ];

  const { dataTableProps, dataTableExportProps } = useDataTable({
    name: 'reporting-auction-results',
    data: campaignData?.data,
    isLoading: campaignIsLoading,
    error: campaignError,
    search: values.search,
    searchKeys: ['campaign.id', 'campaign_name'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: ['impressions', 'clicks', 'ctr', 'cpm', 'cpc', 'cpv', 'total_conversions', 'cpa', 'total_spend'],
    columns: [
      {
        accessor: 'campaign.id',
        header: 'ID',
        render: (value) => value || '-',
      },
      {
        accessor: 'campaign_name',
        header: 'Campaign Name',
        sortable: true,
      },
      {
        header: 'Matched Bid Opportunities',
        accessor: 'matched_bid_opportunities',
        sortable: true,
      },
      {
        header: 'Bids',
        accessor: 'bids',
        sortable: true,
      },
      {
        header: 'Bid Rate',
        accessor: 'bid_rate',
        render: formatPercentage,
        sortable: true,
      },
      {
        header: 'Wins',
        accessor: 'wins',
        sortable: true,
      },
      {
        header: 'Win Rate',
        accessor: 'win_rate',
        render: formatPercentage,
        sortable: true,
      },
    ],
  });

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <ChartContainer>
        <Text size="md" weight={700}>
          {title}
        </Text>
        <Spacing size="lg" />
        <Flex gap="md">
          <Flex gap="md" align="center">
            <Text size="xs">Metric</Text>
            <ControlledSelect name="metric1" control={control} options={AUCTION_METRIC_OPTIONS} width="20rem" />
            <ControlledSelect name="metric2" control={control} options={AUCTION_METRIC_OPTIONS} width="20rem" />
          </Flex>
        </Flex>
        <Spacing size="lg" />
        <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} />
      </ChartContainer>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" align="center">
          <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" />

          <DataTableExport {...dataTableExportProps} />
        </Flex>
        <Spacing size="lg" />
        <DataTable {...dataTableProps} />
      </TableContainer>
    </>
  );
};

const ChartContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
