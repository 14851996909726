import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { DataTable, DataTableColumnCustomize, DataTableExport } from 'src/components/DataTable';
import { ControlledInput, ControlledSwitch } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { useDataTable, useRole } from 'src/hooks';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { REPORTING_DEFAULT_CUSTOMIZE_COLUMNS, REPORTING_SORT_NUMBER_KEYS } from './constants';
import { getReportingMetricsColumns } from './utils';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useEntityTitleGenerator } from 'src/hooks/useEntityTitleGenerator';

const { useAdExchangeQuery } = growthApi;

type ReportingGeoProps = ReportingFormValues;

type ReportingExchangeFormValues = {
  search?: string;
  isWithMarkup?: boolean;
};

export const ReportingAdExchange = (props: ReportingGeoProps) => {
  const {
    timeRange,
    dateFrom,
    dateTo,
    previousDateFrom,
    previousDateTo,
    agencyId,
    advertiserId,
    campaignGroup,
    campaignId,
  } = props;

  const { isAdmin, canWithMarkup } = useRole();
  const { watch, control } = useForm<ReportingExchangeFormValues>({
    defaultValues: {
      isWithMarkup: true,
    },
  });
  const values = watch();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;
  const isShowPrevious = Boolean(previousDateFrom && previousDateTo);

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
  };

  const title = useEntityTitleGenerator({
    agencyId,
    advertiserId,
    campaignId,
    campaignGroup,
    dateFrom,
    dateTo,
  });

  const { data, isFetching, error } = useAdExchangeQuery(commonFilter, { skip: isNotSelectAgencyAdvertiser });

  const {
    data: previousData,
    isFetching: previousIsFetching,
    error: previousError,
  } = useAdExchangeQuery(
    {
      ...commonFilter,
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
    },
    { skip: isNotSelectAgencyAdvertiser || !isShowPrevious },
  );

  const perviousBySupplySource = useMemo(() => {
    return keyBy(previousData?.data, 'supply_source');
  }, [previousData]);

  const { dataTableProps, dataTableExportProps, dataTableCustomizeColumnsProps } = useDataTable({
    name: 'reporting-apps',
    data: data?.data,
    isLoading: isFetching || (isShowPrevious && previousIsFetching),
    error: error || (isShowPrevious && previousError),
    search: values.search,
    searchKeys: ['supply_source'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: REPORTING_SORT_NUMBER_KEYS,
    defaultCustomizeColumns: REPORTING_DEFAULT_CUSTOMIZE_COLUMNS,
    enableTotal: true,
    columns: [
      {
        header: 'Supply Source',
        accessor: 'supply_source',
        sortable: true,
      },
      ...getReportingMetricsColumns({
        isWithMarkup: values.isWithMarkup,
        isAdmin,
        isShowPrevious,
        getPreviousData: (row) => perviousBySupplySource[row.supply_source],
      }),
    ],
  });

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <TableContainer>
      <Text size="md" weight={700}>
        {title}
      </Text>
      <Spacing size="lg" />
      <Flex width="100%" gap="lg" justify="space-between" align="center">
        <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" width="30rem" />
        <Flex gap="lg" align="center">
          {canWithMarkup && (
            <FlexItem shrink={0}>
              <Flex gap="md" align="center">
                <Text size="xs">With markup</Text>
                <ControlledSwitch name="isWithMarkup" control={control} />
              </Flex>
            </FlexItem>
          )}
          <DataTableColumnCustomize {...dataTableCustomizeColumnsProps} />
          <DataTableExport {...dataTableExportProps} />
        </Flex>
      </Flex>
      <Spacing size="lg" />
      <DataTable {...dataTableProps} />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
