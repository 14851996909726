import { format } from 'date-fns';
import { UsePersistentFiltersOptions, usePersistentFilters } from './usePersistentFilters';
import { DateRange, parseDateRange } from 'src/utils';

const DEFAULT_DATE_RANGE = DateRange.Last7Days;

type UseReportingPersistentFiltersProps = Pick<UsePersistentFiltersOptions, 'values' | 'setValues'>;

export const useReportingPersistentFilters = (props: UseReportingPersistentFiltersProps) => {
  const { values, setValues } = props;

  usePersistentFilters({
    filters: ['agencyId', 'advertiserId', 'campaignGroup', 'campaignId', 'timeRange', 'dateTo', 'dateFrom'],
    values,
    setValues,
    persistentFn: (filters) => {
      const persistentFilters = {
        ...filters,
        dateFrom: filters.dateFrom ? format(values.dateFrom, 'yyyy/MM/dd') : null,
        dateTo: filters.dateTo ? format(values.dateTo, 'yyyy/MM/dd') : null,
        campaignId: filters.campaignId ? filters.campaignId.join(',') : null,
      };
      return persistentFilters;
    },
    recoverFn: (filters) => {
      const timeRange = filters.timeRange || DEFAULT_DATE_RANGE;
      const dateFrom = filters.dateFrom ? new Date(filters.dateFrom) : null;
      const dateTo = filters.dateTo ? new Date(filters.dateTo) : null;
      const campaignId = filters.campaignId
        ? typeof filters.campaignId === 'string'
          ? filters.campaignId.split(',').map(Number)
          : filters.campaignId
        : [];
      const recoveredFilters = {
        ...filters,
        campaignId,
        ...parseDateRange(timeRange),
        ...(timeRange === DateRange.Custom
          ? {
              dateFrom,
              dateTo,
            }
          : {}),
      };
      return recoveredFilters;
    },
  });
};
