import { ApexOptions } from 'apexcharts';
import { DataTable, DataTableColumnCustomize, DataTableExport } from 'src/components/DataTable';
import { ControlledInput, ControlledSelect, ControlledSwitch, Progress } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { format } from 'date-fns';
import { TOKENS } from 'src/design';
import ReactApexChart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import {
  autoFillData,
  calcEstBudget,
  calcPacing,
  formatAmount,
  formatOnlyDate,
  formatPercentageWithPrecision,
} from 'src/utils';
import {
  MARKUP_FIELDS,
  METRIC_OPTIONS,
  REPORTING_DEFAULT_CUSTOMIZE_COLUMNS,
  REPORTING_SORT_NUMBER_KEYS,
} from './constants';
import { ReportingFormValues } from './Reporting';
import { useDataTable, UseDataTableOptions, useRole, useWhiteLabelTheme } from 'src/hooks';
import { Loading } from 'src/components/Loading';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { useCallback, useMemo } from 'react';
import { keyBy, sumBy } from 'lodash';
import { getReportingMetricsColumns } from './utils';
import { Icon } from 'src/components/Icon';
import { useEntityTitleGenerator } from 'src/hooks/useEntityTitleGenerator';

const { useCampaignPerformanceQuery } = growthApi;

enum ReportingCampaignGroupBy {
  Campaign = 'campaign',
  Day = 'day',
  Agency = 'agency',
  Advertiser = 'advertiser',
}

type ReportingCampaignFormValues = {
  metric1?: string;
  metric2?: string;
  search?: string;
  isWithMarkup?: boolean;
  groupBy?: ReportingCampaignGroupBy;
  isGroupByDsp?: boolean;
};

type ReportingCampaignProps = ReportingFormValues;

export const ReportingCampaign = (props: ReportingCampaignProps) => {
  const {
    timeRange,
    dateFrom,
    dateTo,
    agencyId,
    advertiserId,
    campaignGroup,
    campaignId,
    previousDateFrom,
    previousDateTo,
  } = props;

  const { isAdmin, canWithMarkup } = useRole();
  const theme = useWhiteLabelTheme();
  const { watch, control } = useForm<ReportingCampaignFormValues>({
    defaultValues: {
      metric1: 'impressions',
      metric2: 'clicks',
      isWithMarkup: true,
      groupBy: ReportingCampaignGroupBy.Campaign,
      isGroupByDsp: false,
    },
  });
  const values = watch();
  const isNotSelectAgencyAdvertiser = !isAdmin && (!agencyId || !advertiserId);
  const isShowPrevious = Boolean(previousDateFrom && previousDateTo);

  const dayBreakout = useMemo(() => {
    if (isAdmin) {
      if (!agencyId) {
        return 'agency_day';
      }
      if (!advertiserId) {
        return 'advertiser_day';
      }
    }
    return 'day';
  }, [advertiserId, agencyId, isAdmin]);

  const tableBreakout = useMemo(() => {
    if (values.groupBy === ReportingCampaignGroupBy.Day) {
      return dayBreakout;
    }
    return values.groupBy;
  }, [dayBreakout, values.groupBy]);

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
  };

  const title = useEntityTitleGenerator({
    agencyId,
    advertiserId,
    campaignId,
    campaignGroup,
    dateFrom,
    dateTo,
  });

  const {
    data: tableData,
    isFetching: tableIsFetching,
    error: tableError,
  } = useCampaignPerformanceQuery(
    {
      ...commonFilter,
      breakout: tableBreakout,
      group_by_dsp: values.isGroupByDsp ? true : undefined,
      with_campaign: true,
      with_agency: true,
      with_advertiser: true,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const {
    data: previousTableData,
    isFetching: previousTableIsFetching,
    error: previousTableError,
  } = useCampaignPerformanceQuery(
    {
      ...commonFilter,
      breakout: tableBreakout,
      group_by_dsp: values.isGroupByDsp ? true : undefined,
      with_campaign: true,
      with_agency: true,
      with_advertiser: true,
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
    },
    { skip: isNotSelectAgencyAdvertiser || !isShowPrevious },
  );

  const previousDataByKey = useMemo(() => {
    const groupByKey = {
      [ReportingCampaignGroupBy.Campaign]: 'campaign_name',
      [ReportingCampaignGroupBy.Day]: 'start_date',
      [ReportingCampaignGroupBy.Agency]: 'agency_name',
      [ReportingCampaignGroupBy.Advertiser]: 'advertiser_name',
    };
    return keyBy(previousTableData?.data || [], groupByKey[values.groupBy]);
  }, [previousTableData?.data, values.groupBy]);

  const { data: chartData, isFetching: chartIsFetching } = useCampaignPerformanceQuery(
    {
      ...commonFilter,
      breakout: dayBreakout,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const { data: previousChartData, isFetching: previousChartIsFetching } = useCampaignPerformanceQuery(
    {
      ...commonFilter,
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
      breakout: dayBreakout,
    },
    { skip: isNotSelectAgencyAdvertiser || !isShowPrevious },
  );

  const autoFilledChartData = useMemo(() => {
    return autoFillData(chartData?.data, dateFrom, dateTo);
  }, [chartData?.data, dateFrom, dateTo]);
  const autoFilledPreviousChartData = useMemo(() => {
    return autoFillData(previousChartData?.data, previousDateFrom, previousDateTo);
  }, [previousChartData?.data, previousDateFrom, previousDateTo]);

  const metricWithMarkup = useCallback(
    (row: any, metric: string) => {
      if (values.isWithMarkup && MARKUP_FIELDS.includes(metric)) {
        return row[`${metric}_markup`] || 0;
      }
      return row[metric] || 0;
    },
    [values.isWithMarkup],
  );

  const chartOptions: ApexOptions = {
    colors: [theme.color.primary, theme.color.highlight],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      categories: autoFilledChartData.map((row) => row.start_date) || [],
    },
    yaxis: [
      {
        min: 0,
        forceNiceScale: true,
        decimalsInFloat: 2,
      },
      !isShowPrevious && {
        min: 0,
        forceNiceScale: true,
        decimalsInFloat: 2,
        opposite: true,
      },
    ].filter(Boolean),
    tooltip: {
      hideEmptySeries: false,
    },
    stroke: {
      curve: 'straight',
      dashArray: isShowPrevious ? [0, 8] : undefined,
      width: 4,
    },
    fill: {
      type: 'solid',
      opacity: isShowPrevious ? [0.35, 1] : [0.35, 0.35],
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: METRIC_OPTIONS.find((option) => option.value === values.metric1)?.label,
      type: 'area',
      data: autoFilledChartData.map((row) => metricWithMarkup(row, values.metric1)) || [],
    },
  ];

  if (isShowPrevious) {
    chartSeries.push({
      name: METRIC_OPTIONS.find((option) => option.value === values.metric1)?.label + ' (previous)',
      type: 'line',
      data: autoFilledPreviousChartData.map((row) => metricWithMarkup(row, values.metric1)) || [],
    });
  } else {
    chartSeries.push({
      name: METRIC_OPTIONS.find((option) => option.value === values.metric2)?.label,
      type: 'area',
      data: autoFilledChartData.map((row) => metricWithMarkup(row, values.metric2)) || [],
    });
  }

  const finalTableData = useMemo(() => {
    return tableData?.data?.map((row) => {
      const totalSpend = values.isWithMarkup
        ? row.total_spend_markup
        : isAdmin
        ? row.total_spend
        : row.total_spend_our_markup;
      const estBudget = calcEstBudget(dateFrom, dateTo, row.campaign);
      const pacing = calcPacing(dateFrom, dateTo, totalSpend, row.campaign, estBudget);
      return {
        ...row,
        est_budget: estBudget,
        pacing,
      };
    });
  }, [tableData?.data, dateFrom, dateTo, isAdmin, values.isWithMarkup]);

  const getUseDataTableOptions = (): UseDataTableOptions => {
    const dataTableOptions: UseDataTableOptions = {
      name: `reporting-campaign-${values.groupBy}`,
      data: finalTableData,
      isLoading: tableIsFetching || (isShowPrevious && previousTableIsFetching),
      error: tableError || (isShowPrevious && previousTableError),
      search: values.search,
      defaultSort: {
        key: 'impressions',
        direction: 'desc',
      },
      sortNumberKeys: REPORTING_SORT_NUMBER_KEYS,
      defaultCustomizeColumns: REPORTING_DEFAULT_CUSTOMIZE_COLUMNS,
      enableTotal: true,
    };
    switch (values.groupBy) {
      case ReportingCampaignGroupBy.Campaign:
        dataTableOptions.searchKeys = ['campaign.id', 'campaign_name', 'dsp'];
        dataTableOptions.defaultCustomizeColumns = [
          'Pacing',
          'est. Budget',
          'Status',
          ...REPORTING_DEFAULT_CUSTOMIZE_COLUMNS,
        ];
        dataTableOptions.columns = [
          {
            header: 'ID',
            accessor: 'campaign.id',
            render: (value) => value || '-',
            sortable: true,
            totalRender: () => {
              return 'Total';
            },
          },
          {
            header: 'Campaign Name',
            accessor: 'campaign_name',
            sortable: true,
          },
          {
            header: 'DSP',
            accessor: 'dsp',
            when: () => values.isGroupByDsp,
          },
          {
            header: 'Start Date',
            accessor: 'campaign.schedule_start_date',
            render: formatOnlyDate,
            sortable: true,
            customizeGroup: 'Campaign Specifics',
          },
          {
            header: 'End Date',
            accessor: 'campaign.schedule_end_date',
            render: formatOnlyDate,
            sortable: true,
            customizeGroup: 'Campaign Specifics',
          },
          ...getReportingMetricsColumns({
            isShowPrevious,
            getPreviousData: (row) => previousDataByKey[row.campaign_name],
            isWithMarkup: values.isWithMarkup,
            isAdmin,
          }),
          {
            header: 'Pacing',
            accessor: 'pacing.value',
            width: '8rem',
            render: (_, row) => {
              if (!row.campaign) {
                return '-';
              }
              const pacing = row.pacing;
              let color = 'success';
              if (pacing.money > 1 || pacing.money < 0.5) {
                color = 'error';
              } else if (pacing.money >= 0.5 && pacing.money <= 0.85) {
                color = 'warn';
              }
              return (
                <Flex direction="column" gap="md">
                  <Flex justify="space-between" align="center" gap="md">
                    <Flex align="center" gap="xxs">
                      <Icon size="xs" type="time" />
                      <Text size="xxs">
                        {formatPercentageWithPrecision(pacing.time === null ? null : pacing.time * 100, 0)}
                      </Text>
                    </Flex>
                    <Flex align="center" gap="xxs">
                      <Icon size="xs" type="money" />
                      <Text size="xxs">{formatPercentageWithPrecision(pacing.money * 100, 0)}</Text>
                    </Flex>
                  </Flex>
                  <Progress value={Math.min(pacing.money * 100, 100)} height="0.8rem" color={color} />
                </Flex>
              );
            },
            sortable: true,
            customizeGroup: 'Campaign Specifics',
          },
          {
            header: 'est. Budget',
            accessor: 'est_budget',
            render: (value, row) => {
              if (!row.campaign) {
                return '-';
              }
              return formatAmount(value);
            },
            totalRender: (data) => {
              return formatAmount(sumBy(data, 'est_budget'));
            },
            sortable: true,
            customizeGroup: 'Campaign Specifics',
          },
          {
            header: 'Status',
            accessor: 'campaign.status',
            render: (value) => value || '-',
            sortable: true,
            customizeGroup: 'Campaign Specifics',
          },
        ];
        break;
      case ReportingCampaignGroupBy.Day:
        dataTableOptions.searchKeys = ['start_date'];
        dataTableOptions.defaultSort = {
          key: 'start_date',
          direction: 'desc',
        };
        dataTableOptions.columns = [
          {
            header: 'Day',
            accessor: 'start_date',
            sortable: true,
            totalRender: () => {
              return 'Total';
            },
          },
          ...getReportingMetricsColumns({
            isShowPrevious,
            getPreviousData: (row) => previousDataByKey[row.start_date],
            isWithMarkup: values.isWithMarkup,
            isAdmin,
          }),
        ];
        break;
      case ReportingCampaignGroupBy.Agency:
        dataTableOptions.columns = [
          {
            header: 'Agency',
            accessor: 'agency.name',
            sortable: true,
            render: (value) => value || '-',
            totalRender: () => {
              return 'Total';
            },
          },
          ...getReportingMetricsColumns({
            isShowPrevious,
            getPreviousData: (row) => previousDataByKey[row.start_date],
            isWithMarkup: values.isWithMarkup,
            isAdmin,
          }),
        ];
        break;
      case ReportingCampaignGroupBy.Advertiser:
        dataTableOptions.columns = [
          {
            header: 'Advertiser',
            accessor: 'advertiser.name',
            sortable: true,
            render: (value) => value || '-',
            totalRender: () => {
              return 'Total';
            },
          },
          ...getReportingMetricsColumns({
            isShowPrevious,
            getPreviousData: (row) => previousDataByKey[row.start_date],
            isWithMarkup: values.isWithMarkup,
            isAdmin,
          }),
        ];
        break;
    }
    return dataTableOptions;
  };

  const { dataTableProps, dataTableExportProps, dataTableCustomizeColumnsProps } = useDataTable(
    getUseDataTableOptions(),
  );

  const groupByOptions = useMemo(() => {
    const options = [
      { value: ReportingCampaignGroupBy.Campaign, label: 'Campaign' },
      { value: ReportingCampaignGroupBy.Day, label: 'Day' },
    ];
    if (isAdmin) {
      options.push({ value: ReportingCampaignGroupBy.Agency, label: 'Agency' });
      options.push({ value: ReportingCampaignGroupBy.Advertiser, label: 'Advertiser' });
    }
    return options;
  }, [isAdmin]);

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <ChartContainer>
        <Text size="md" weight={700}>
          {title}
        </Text>
        <Spacing size="lg" />
        <Flex gap="md">
          <Flex gap="md" align="center">
            <Text size="xs">Metric</Text>
            <ControlledSelect name="metric1" control={control} options={METRIC_OPTIONS} width="20rem" />
            {!isShowPrevious && (
              <ControlledSelect name="metric2" control={control} options={METRIC_OPTIONS} width="20rem" />
            )}
          </Flex>
        </Flex>
        <Spacing size="lg" />
        {chartIsFetching || (isShowPrevious && previousChartIsFetching) ? (
          <Loading height="30rem" />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={300} />
        )}
      </ChartContainer>
      <Spacing size="lg" />
      <TableContainer>
        <Flex justify="space-between" align="center" width="100%">
          <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" width="30rem" />
          <Flex gap="lg" align="center">
            <Flex gap="md" align="center">
              <Text size="xs">Group by</Text>
              <ControlledSelect name="groupBy" control={control} options={groupByOptions} width="15rem" />
            </Flex>
            {isAdmin && values.groupBy === ReportingCampaignGroupBy.Campaign && (
              <Flex gap="md" align="center">
                <Text size="xs">Split by DSP</Text>
                <ControlledSwitch name="isGroupByDsp" control={control} />
              </Flex>
            )}
            {canWithMarkup && (
              <Flex gap="md" align="center">
                <Text size="xs">With markup</Text>
                <ControlledSwitch name="isWithMarkup" control={control} />
              </Flex>
            )}
            <DataTableColumnCustomize {...dataTableCustomizeColumnsProps} />
            <DataTableExport {...dataTableExportProps} />
          </Flex>
        </Flex>
        <Spacing size="lg" />
        <DataTable {...dataTableProps} scroll />
      </TableContainer>
    </>
  );
};

const ChartContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
